import { getDateNoTime } from '@assets/js/dateUtils';

const options = [
  { value: 1, label: '新款' },
  { value: 2, label: '老款' },
  { value: 3, label: '修改款' },
  { value: 4, label: '报价' },
  { value: 5, label: '其他' }
];

const smplQuotlist = [
  { value: 1, label: '打样并报价' },
  { value: 2, label: '试样报价' },
  { value: 3, label: '仅打样' },
  { value: 4, label: '估价' },
  { value: 5, label: '返工' },
  { value: 6, label: '仅报价' },
  { value: 7, label: '其他' }
];

export const tableProperties = [
  {
    label: '操作',
    prop: 'operation',
    itemType: 'input',
    input: false,
    fixed: 'left',
    labelWidth: 80
  },
  {
    label: '部门',
    prop: 'dept_name',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 100,
    multiple: true,
    filterable: true,
    collapseTags: true
  },
  {
    label: '产品图片',
    prop: 'imge_url',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 130,
    subItem: { type: 'image', image: row => row.imge_url, thumbnail: row => row.imge_url }
  },
  {
    label: '公司抬头',
    prop: 'cptt_name',
    otherProp: 'cpttNameList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    labelWidth: 150,
    multiple: true,
    filterable: true,
    collapseTags: true
  },
  {
    label: '打样委托人',
    prop: 'requ_stff_name',
    otherProp: 'requStffNameList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    labelWidth: 100,
    multiple: true,
    filterable: true,
    collapseTags: true
  },
  {
    label: '采购员',
    prop: 'stff_name',
    otherProp: 'stffNameList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    labelWidth: 100,
    multiple: true,
    filterable: true,
    collapseTags: true
  },
  {
    label: '供应商简称',
    prop: 'supp_abbr',
    otherProp: 'suppAbbrList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    labelWidth: 110,
    multiple: true,
    filterable: true,
    collapseTags: true
  },
  {
    label: '客户货号',
    prop: 'prod_cust_no',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 100
  },
  {
    label: '剩余交样天数',
    prop: 'surplus_day',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 120,
    visible: true
  },
  {
    label: '要求交期',
    prop: 'fequ_pedate',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: 100,
    formatter: val => getDateNoTime(val, true)
  },
  {
    label: '客户简称',
    prop: 'cust_abbr',
    otherProp: 'custAbbrList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    labelWidth: 120,
    multiple: true,
    filterable: true,
    collapseTags: true
  },
  {
    label: '收单日期',
    prop: 'create_time',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: 100,
    formatter: val => getDateNoTime(val, true)
  },
  {
    label: '打样天数',
    prop: 'sample_cycle',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 100
  },
  {
    label: '打样单号',
    prop: 'requ_no',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 120
  },
  {
    label: '我司货号',
    prop: 'prod_no',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 120
  },
  { label: '中文名称', prop: 'prod_name', itemType: 'input', input: true, sortable: false, labelWidth: 120 },
  { label: '打样数量', prop: 'fequ_smpl_num', itemType: 'input', input: false, sortable: true, labelWidth: 100 },
  {
    label: '产品类型',
    prop: 'prod_type_code',
    otherProp: 'prodNoCodeList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    labelWidth: 110,
    multiple: true,
    filterable: true,
    collapseTags: true
  },
  {
    label: '样品要求',
    prop: 'requ_sample_ask',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 100,
    subItem: { type: 'popoverInput', disabled: true }
  },
  {
    label: '委托类型',
    prop: 'fequ_smpl_quot',
    itemType: 'select',
    options: smplQuotlist,
    input: true,
    sortable: false,
    labelWidth: 100,
    formatter: val => smplQuotlist.find(x => x.value === val).label
  },
  {
    label: '样品材质',
    prop: 'fequ_smpl_made',
    otherProp: 'fequSmplMadeList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    labelWidth: 100,
    multiple: true,
    filterable: true,
    collapseTags: true
  },
  {
    label: '样品类别',
    prop: 'fequ_smpl_type',
    otherProp: 'fequSmplTypeList',
    itemType: 'select',
    options: options,
    formatter: val => options.find(x => x.value === val).label,
    input: true,
    sortable: false,
    labelWidth: 100,
    multiple: true,
    filterable: true,
    collapseTags: true
  },
  {
    label: '难易等级',
    prop: 'difficulty_level',
    otherProp: 'difficultyLevelList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    labelWidth: 100,
    multiple: true,
    filterable: true,
    collapseTags: true,
    visible: true
  },
  { label: '裁片数', prop: 'cutting_pieces_num', itemType: 'input', input: false, sortable: false, labelWidth: 100 },
  {
    label: '开版师',
    prop: 'open_stff_name',
    otherProp: 'openStffNameList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    labelWidth: 100,
    multiple: true,
    filterable: true,
    collapseTags: true
  },
  {
    label: '核价接收人',
    prop: 'requ_smpl_pricer_name',
    otherProp: 'requSmplPricerNameList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    labelWidth: 100,
    multiple: true,
    filterable: true,
    collapseTags: true
  },
  {
    label: '完成日期',
    prop: 'fequ_smpl_redate',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: 100,
    formatter: val => getDateNoTime(val, true)
  },
  {
    label: '准时率',
    prop: 'on_time',
    itemType: 'select',
    options: [
      { label: '准时', value: '准时' },
      { label: '不准时', value: '不准时' }
    ],
    input: true,
    sortable: false,
    labelWidth: 100
  },
  { label: '晚交天数', prop: 'late_submission_days', itemType: 'input', input: false, sortable: true, labelWidth: 100 },
  {
    label: '晚交原因',
    prop: 'reasons_late_submission',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 130,
    subItem: { type: 'popoverInput', maxLength: 100 }
  },
  {
    label: '合格率',
    prop: 'fequ_qualified',
    itemType: 'select',
    input: true,
    options: [
      { label: '合格', value: 0 },
      { label: '不合格', value: 1 }
    ],
    sortable: false,
    labelWidth: 100,
    subItem: {
      type: 'newSelect',
      options: {
        0: { value: 0, label: '合格' },
        1: { value: 1, label: '不合格' }
      }
    }
  },
  {
    label: '退样(差错部门)',
    prop: 'sample_dept_id',
    otherProp: 'sample_dept_name',
    itemType: 'input',
    filterable: true,
    input: false,
    sortable: false,
    labelWidth: 120,
    subItem: { type: 'newSelect', options: {} }
  },
  {
    label: '委托单合格率',
    prop: 'requ_qualified',
    ohterProp: 'requQualifiedList',
    itemType: 'select',
    options: [],
    filterable: true,
    input: false,
    sortable: false,
    labelWidth: 100,
    subItem: {
      disabled: true,
      type: 'newSelect',
      options: {
        0: { value: 0, label: '合格' },
        1: { value: 1, label: '不合格' }
      }
    }
  },
  {
    label: '退单原因',
    prop: 'chargeback_reason',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 130,
    subItem: { type: 'popoverInput', disabled: true }
  },
  {
    label: '退单(差错部门)',
    prop: 'chargeback_dept_id',
    otherProp: 'chargeback_dept_name',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 120,
    subItem: { type: 'newSelect', options: {} }
  }
];
export const subTableProperties = [
  { label: '样品状态', prop: 'sample_status', itemType: 'input', input: false, sortable: false, widthAuto: false },
  { label: '产品图片', prop: 'picture_url', itemType: 'input', input: false, sortable: false, widthAuto: false, subItem: { type: 'input' } },
  { label: '我司货号', prop: 'prod_no', itemType: 'input', input: false, sortable: false, widthAuto: false, subItem: { type: 'input' } },
  { label: '客户货号', prop: 'prod_cust_no', itemType: 'input', input: false, sortable: false, widthAuto: false, subItem: { type: 'input', disabled: true } },
  { label: '更改号', prop: 'prod_suffix', itemType: 'input', input: false, sortable: false, widthAuto: false, subItem: { type: 'input', disabled: true } },
  {
    label: '老ERP货号',
    prop: 'old_prod_cust_no',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { type: 'input', disabled: true }
  },
  { label: '工厂报价', prop: 'fequ_smpl_cost', itemType: 'input', input: false, sortable: false, widthAuto: false, subItem: { type: 'input', disabled: true } },
  {
    label: '采购核价',
    prop: 'fequ_smpl_recost',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { type: 'input', disabled: true }
  },
  { label: '产品名称', prop: 'prod_name', itemType: 'input', input: false, sortable: false, widthAuto: false, subItem: { type: 'input', disabled: true } },
  { label: '模具编号', prop: 'mould_no', itemType: 'input', input: false, sortable: false, widthAuto: false, subItem: { type: 'input', disabled: true } },
  { label: '产品规格', prop: 'prod_spec', itemType: 'input', input: false, sortable: false, widthAuto: false, subItem: { type: 'input', disabled: true } },
  {
    label: '难度等级',
    prop: 'difficulty_level',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { type: 'input', disabled: true }
  },
  { label: '样品数量', prop: 'fequ_smpl_num', itemType: 'input', input: false, sortable: false, widthAuto: false, subItem: { type: 'input', disabled: true } },
  { label: '产品单位', prop: 'prod_unit', itemType: 'input', input: false, sortable: false, widthAuto: false, subItem: { type: 'input', disabled: true } },
  { label: '产品内容', prop: 'prod_content', itemType: 'input', input: false, sortable: false, widthAuto: false, subItem: { type: 'input', disabled: true } },
  { label: '样品类型', prop: 'fequ_smpl_type', itemType: 'input', input: false, sortable: false, widthAuto: false, subItem: { type: 'input', disabled: true } },
  {
    label: '委托类型',
    prop: 'fequ_smpl_quot',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { required: true, type: 'input', disabled: true }
  },
  {
    label: '样品类别',
    prop: 'prod_type',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { required: true, type: 'input', disabled: true }
  },
  { label: '样品材质', prop: 'fequ_smpl_made', itemType: 'input', input: false, sortable: false, widthAuto: false, subItem: { type: 'input', disabled: true } },
  {
    label: '产品英文名称',
    prop: 'prod_ename',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { required: true, type: 'input', disabled: true }
  },
  { label: '系列名称', prop: 'series_name', itemType: 'input', input: false, sortable: false, widthAuto: false, subItem: { type: 'input', disabled: true } },
  { label: '客户编号', prop: 'cust_num', itemType: 'input', input: false, sortable: false, widthAuto: false, subItem: { type: 'input', disabled: true } },
  {
    label: '样品要求',
    prop: 'requ_sample_ask',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { type: 'input', disabled: true }
  },
  { label: '打样接收人', prop: 'fequ_taker', itemType: 'input', input: false, sortable: false, widthAuto: false, subItem: { type: 'input', disabled: true } },
  {
    label: '报价接收人',
    prop: 'quote_stff_id',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { type: 'input', disabled: true }
  },
  { label: '核价接收人', prop: 'fequ_pricer', itemType: 'input', input: false, sortable: false, widthAuto: false, subItem: { type: 'input', disabled: true } },
  {
    label: '要求完成日期',
    prop: 'fequ_pedate',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '实际完成日期',
    prop: 'fequ_smpl_redate',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { required: true, type: 'input', disabled: true }
  },
  { label: 'DYJ报价', prop: 'quta_quote', itemType: 'input', input: false, sortable: false, widthAuto: false, subItem: { type: 'input', disabled: true } },
  {
    label: '核价份数',
    prop: 'fequ_pricing_num',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { type: 'input', disabled: true }
  },
  { label: '采购部核价', prop: 'quta_price', itemType: 'input', input: false, sortable: false, widthAuto: false, subItem: { type: 'input', disabled: true } },
  {
    label: '晚交原因',
    prop: 'fequ_smpl_remark',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { type: 'input', disabled: true }
  },
  { label: '备注', prop: 'remark', itemType: 'input', input: false, sortable: false, widthAuto: false, subItem: { type: 'input', disabled: true } },
  {
    label: '退样次数',
    prop: 'business_back_num',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { type: 'input', disabled: true }
  },
  {
    label: '退样原因',
    prop: 'business_back_reason',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    subItem: { type: 'input', disabled: true }
  }
];
