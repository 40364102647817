<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16 flexV">
        <el-date-picker
          size="small"
          class="topDateRangeSearch vg_mr_8"
          v-model="dateRange1"
          :picker-options="pickerOptions"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          end-placeholder="要求交期结束日期"
          start-placeholder="要求交期开始日期"
        />
        <el-date-picker
          size="small"
          class="topDateRangeSearch vg_mr_8"
          v-model="dateRange2"
          :picker-options="pickerOptions"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="收单开始日期"
          end-placeholder="收单结束日期"
        />
        <el-date-picker
          size="small"
          class="topDateRangeSearch vg_mr_8"
          v-model="dateRange3"
          :picker-options="pickerOptions"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="完成开始日期"
          end-placeholder="完成结束日期"
        />
        <el-button icon="el-icon-search" size="small" type="primary" @click="getTableData()">查询</el-button>
        <el-button icon="el-icon-refresh-right" size="small" type="info" @click="buttonRefresh()"> 刷新</el-button>
        <el-button size="small" type="success" @click="exportExcel()">导出Excel</el-button>
        <el-popover v-if="tabIndex === 1" class="vg_ml_8" placement="bottom" trigger="click" width="400">
          <DynamicUTable
            ref="sampleRoomSituation"
            :autoHeight="true"
            :columns="sampleRoomSituationTableProperties"
            :need-all-column-summary="true"
            :need-check-box="false"
            :need-fixed-height="false"
            :need-index="false"
            :need-pagination="false"
            :need-search="false"
            :show-summary="true"
            :tableData="sampleRoomSituationTableData"
          >
            <template v-slot:header-operation="scope">
              <el-select
                v-model="supp_id"
                clearable
                filterable
                placeholder="请选择供应商简称"
                size="small"
                @change="getSampleRoomSituationTableData"
                @clear="getSampleRoomSituationTableData"
              >
                <el-option v-for="item in suppList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </template>
          </DynamicUTable>
          <el-button slot="reference" size="small" type="primary" @click="getSampleRoomSituationTableData">工厂情况 </el-button>
        </el-popover>
        <span style="margin-left: auto; margin-right: 10px">打样总数量：{{ sumNum }}</span>
      </div>
      <el-radio-group size="small" v-model="activeName" @input="changeTableIndex">
        <el-radio-button v-if="tabIndex === 1 || currentStffId === 1" label="毛绒"></el-radio-button>
        <el-radio-button v-if="tabIndex === 2 || currentStffId === 1" label="包材"></el-radio-button>
        <el-radio-button v-if="tabIndex === 3 || currentStffId === 1" label="非毛绒"></el-radio-button>
      </el-radio-group>
      <DynamicUTable
        :needChangeColumnProperties="true"
        v-loading="loadFlag"
        ref="multiTable"
        :tableData="tableData"
        :columns="c_tableProperties"
        :page-size="50"
        :total-page="totalPage"
        :need-search="true"
        :need-check-box="false"
        :need-pagination="true"
        :need-fixed-height="true"
        :rowStyle="
          ({ row, rowIndex }) => {
            return { height: '80px' };
          }
        "
        @getTableData="getTableData"
      >
        <template v-slot:operation="scope">
          <el-button type="primary" size="mini" @click="saveRow(scope.row, scope.$index)">保存</el-button>
        </template>
      </DynamicUTable>
    </el-card>
  </div>
</template>

<script>
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { downloadFile, getDept, getStff, getSupp, setTime } from '@api/public';
import { fequAPI } from '@api/modules/fequ';
import { tableProperties } from '@/views/DevelopManagement/FequManage/fequ';
import { cloneDeep } from 'lodash';

export default {
  name: 'DataList',
  components: {
    DynamicUTable
  },
  data() {
    return {
      activeName: '',
      dateRange1: [],
      dateRange2: [],
      dateRange3: [],
      loadFlag: false,
      totalPage: 0,
      tableData: [],
      tableProperties: cloneDeep(tableProperties),
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      tabIndex: '',
      currentDeptId: this.$cookies.get('userInfo').dept_id,
      currentStffId: this.$cookies.get('userInfo').stff_id,
      sumNum: 0,
      sampleRoomSituationTableData: [],
      sampleRoomSituationTableProperties: [
        { label: '操作', prop: 'operation', itemType: 'input', input: false, labelWidth: 120 },
        { label: '样品款数', prop: 'product', itemType: 'input', input: false },
        { label: '样品数量', prop: 'sum_product', itemType: 'input', input: false }
      ],
      supp_id: 68,
      suppList: [],
      deptStaffNameList: [],
      deptNameList: []
    };
  },
  computed: {
    c_tableProperties() {
      this.tableProperties.forEach(item => {
        switch (item.label) {
          case '采购员':
            item.options = this.deptStaffNameList;
            break;
          case '退单(差错部门)':
          case '退样(差错部门)':
            item.subItem.options = this.deptNameList[0];
            item.options = this.deptNameList[1];
            break;
          case '剩余交样天数':
            item.visible = this.activeName !== '毛绒';
            break;
          case '难易等级':
            item.visible = !(this.activeName === '毛绒' || this.activeName === '包材');
            break;
        }
      });
      this.$refs.multiTable?.reloadTable();
      if (this.activeName === '包材') {
        const first = [
          'operation',
          'create_time',
          'requ_no',
          'imge_url',
          'prod_cust_no',
          'prod_no',
          'prod_name',
          'requ_sample_ask',
          'fequ_smpl_num',
          'fequ_smpl_type',
          'fequ_smpl_quot',
          'fequ_pedate',
          'fequ_smpl_redate',
          'sample_cycle',
          'surplus_day',
          'cust_abbr',
          'requ_stff_name',
          'supp_abbr',
          'stff_name',
          'requ_smpl_pricer_name',
          'fequ_smpl_made',
          'dept_name',
          'prod_type_code',
          'on_time',
          'late_submission_days',
          'reasons_late_submission',
          'fequ_qualified',
          'sample_dept_id',
          'requ_qualified',
          'chargeback_reason',
          'chargeback_dept_id',
          'cutting_pieces_num',
          'open_stff_name',
          'cptt_name'
        ];
        return this.tableProperties
          .filter(property => first.includes(property.prop))
          .sort((a, b) => {
            return first.indexOf(a.prop) - first.indexOf(b.prop);
          })
          .concat(this.tableProperties.filter(property => !first.includes(property.prop)));
      }
      return this.tableProperties;
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      let caigou1 = await getDept(['采购一部'], 'ids');
      let caigou2 = await getDept(['采购二部'], 'ids');
      let fuliao = await getDept(['辅料部'], 'ids');
      this.deptStaffNameList = await getStff(this.$cookies.get('userInfo').dept_name, 'default', 'dept_name', 'stff_name');
      this.deptNameList = await getDept(['业务', '电商', '内贸', '采购', '辅料'], 'valueListAndDefault');
      this.suppList = await getSupp();
      if (caigou1[0] === this.currentDeptId || this.currentStffId === 1) {
        this.tabIndex = 1;
        this.activeName = '毛绒';
      } else if (fuliao[0] === this.currentDeptId) {
        this.tabIndex = 2;
        this.activeName = '包材';
      } else if (caigou2[0] === this.currentDeptId) {
        this.tabIndex = 3;
        this.activeName = '非毛绒';
      }
      this.loadFlag = false;
      setTimeout(() => {
        this.getTableData();
      });
    },
    changeTableIndex(val) {
      switch (val) {
        case '毛绒':
          this.tabIndex = 1;
          this.activeName = '毛绒';
          break;
        case '包材':
          this.tabIndex = 2;
          this.activeName = '包材';
          break;
        case '非毛绒':
          this.tabIndex = 3;
          this.activeName = '非毛绒';
          break;
      }
      this.$refs.multiTable.resetFields();
      setTimeout(() => {
        this.getTableData();
      }, 500);
    },
    extracted() {
      let searchForm = cloneDeep(this.$refs.multiTable.searchForm);
      setTime(searchForm, 'startTime', 'endTime', this.dateRange1 || [], true);
      setTime(searchForm, 'startTime2', 'endTime2', this.dateRange2 || [], true);
      setTime(searchForm, 'startTime3', 'endTime3', this.dateRange3 || [], true);
      searchForm.requ_type = this.tabIndex;
      searchForm.requStffNameList = searchForm.requStffNameList?.toString(); //打样委托人
      searchForm.suppAbbrList = searchForm.suppAbbrList?.toString(); //工厂简称
      searchForm.custAbbrList = searchForm.custAbbrList?.toString(); //客户简称
      searchForm.cpttNameList = searchForm.cpttNameList?.toString(); //公司抬头
      searchForm.stffNameList = searchForm.stffNameList?.toString(); //采购员
      searchForm.prodNoCodeList = searchForm.prodNoCodeList?.toString(); //产品类型
      searchForm.fequSmplMadeList = searchForm.fequSmplMadeList?.toString(); //样品材质
      searchForm.fequSmplTypeList = searchForm.fequSmplTypeList?.toString(); //样品类别
      searchForm.difficultyLevelList = searchForm.difficultyLevelList?.toString(); //难易等级
      searchForm.openStffNameList = searchForm.openStffNameList?.toString(); //开版师
      searchForm.requSmplPricerNameList = searchForm.requSmplPricerNameList?.toString(); //核价接受人
      searchForm.requQualifiedList = searchForm.requQualifiedList?.toString(); //委托打样单合格率
      return searchForm;
    },
    getTableData() {
      this.loadFlag = true;
      let searchForm = this.extracted();
      fequAPI.getRequestSampleList(searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.sumNum = data.sumNum;
        this.tableProperties.forEach(item => {
          if (item.otherProp === 'requStffNameList') item.options = data.requStffNameList;
          if (item.otherProp === 'suppAbbrList') item.options = data.suppAbbrList;
          if (item.otherProp === 'custAbbrList') item.options = data.custAbbrList;
          if (item.otherProp === 'cpttNameList') item.options = data.cpttNameList;
          // if (item.otherProp === 'stffNameList') item.options = data.stffNameList;
          if (item.otherProp === 'prodNoCodeList') item.options = data.prodNoCodeList;
          if (item.otherProp === 'fequSmplMadeList') item.options = data.fequSmplMadeList;
          // if (item.otherProp === 'fequSmplTypeList') item.options = data.fequSmplTypeList;
          if (item.otherProp === 'difficultyLevelList') item.options = data.difficultyLevelList;
          if (item.otherProp === 'openStffNameList') item.options = data.openStffNameList;
          if (item.otherProp === 'requSmplPricerNameList') item.options = data.requSmplPricerNameList;
          if (item.otherProp === 'requQualifiedList') item.options = data.requQualifiedList;
        });
        this.loadFlag = false;
        this.$refs.multiTable.updateData();
      });
    },
    buttonRefresh() {
      this.dateRange1 = [];
      this.dateRange2 = [];
      this.dateRange3 = [];
      this.$refs.multiTable.resetFields();
      this.getTableData();
    },
    exportExcel() {
      let requestBody = { type: 1, ...this.extracted() };
      fequAPI.ecportRequestSampleList(requestBody).then(({ data }) => {
        downloadFile({ fileUrl: data.url, fileName: data.fileName, type: '' });
      });
    },
    saveRow(row, index) {
      fequAPI.editRequestSampleList(row).then(({ data }) => {
        this.$message.success('保存成功!');
      });
    },
    getSampleRoomSituationTableData() {
      let searchForm = this.extracted();
      searchForm.supp_id = this.supp_id;
      fequAPI.getRequestSampleSuppList(searchForm).then(({ data }) => {
        this.sampleRoomSituationTableData = [
          { operation: '新款', product: data.new_product, sum_product: data.sum_new_product },
          { operation: '老款', product: data.old_product, sum_product: data.sum_old_product },
          { operation: '修改款', product: data.modified_product, sum_product: data.sum_modified_product }
        ];
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
